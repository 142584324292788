import React from "react";
import { Link } from "gatsby";
import { LinkIcon } from "../shared/links";
import { ItemIcon, ItemsIcon, ArrowRegIcon } from "../../images/icons";
import {
  responsiveTextBasic,
  responsiveIconsBasic,
  stickyNav,
} from "../../components/shared/styles";

export const WorkNavbar = ({
  isSeries,
  artist,
  title,
  selectedItem,
  setSelectedItem,
}) => {
  return isSeries ? (
    <div
      className={`flex capitalize items-center ${responsiveTextBasic} mb-xl ${stickyNav} bg-yellow-50`}
    >
      {/* Artist Name + LInk to Artist */}
      <Link to={`/artist/${artist.slug}`}>
        <LinkIcon classes={responsiveTextBasic}>
          {artist.firstName} {artist.lastName}
        </LinkIcon>
      </Link>
      {/* > */}
      <div className="mx-5">
        <ArrowRegIcon
          classes={`${
            isSeries && selectedItem != null ? "opacity-100" : "opacity-50"
          } ${responsiveIconsBasic}`}
        />
      </div>
      {/* Work Title */}
      {isSeries && selectedItem != null ? (
        <button className={"capitalize"} onClick={() => setSelectedItem(null)}>
          <LinkIcon classes={responsiveTextBasic}>{title} </LinkIcon>
        </button>
      ) : (
        <div className="capitalize opacity-50">{title}</div>
      )}
      {/* > */}
      <div className="mx-5">
        <ArrowRegIcon classes={` opacity-50 ${responsiveIconsBasic}`} />
      </div>
      {/* Series Item / Series Items  */}{" "}
      {isSeries && selectedItem != null ? (
        <div className="opacity-50 flex items-center">
          <ItemIcon classes={`${responsiveIconsBasic} mr-5`} />
          <div>Series Item</div>
        </div>
      ) : (
        <div className="opacity-50 flex items-center">
          <ItemsIcon classes={`${responsiveIconsBasic} mr-5`} />
          <div> Series Items</div>
        </div>
      )}
    </div>
  ) : (
    <div
      className={`flex capitalize items-center ${responsiveTextBasic} mb-xl ${stickyNav} bg-yellow-50`}
    >
      {/* Artist Name + LInk to Artist */}

      <Link to={`/artist/${artist.slug}`}>
        <LinkIcon classes={responsiveTextBasic}>
          {artist.firstName} {artist.lastName}
        </LinkIcon>
      </Link>

      {/* > */}
      <div className="mx-5">
        <ArrowRegIcon
          classes={`${
            isSeries && selectedItem != null ? "opacity-100" : "opacity-50"
          } ${responsiveIconsBasic}`}
        />
      </div>
      {/* Work Title */}

      <div className="capitalize opacity-50">{title}</div>
    </div>
  );
};
