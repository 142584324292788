import * as React from "react";
import useKeyPress from "../../utils/useKeyPress";
import { CloseIcon } from "../../images/icons";
import { motion, AnimatePresence } from "framer-motion";

export default function ModalZoom({ visible, onClose, children }) {
  // Esc close
  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      onClose();
    }
  }
  const pressed = useKeyPress("Escape");
  pressed && onClose();

  //   if (!visible) return null;

  return (
    // ---> Modal <--- //

    <AnimatePresence>
      {visible ? (
        <motion.div
          role={"button"}
          className={
            "bg-black bg-opacity-100 flex fixed justify-center items-center z-[50] inset-0  pointer-events-auto"
          }
          onKeyDown={handleKeyDown}
          onClick={onClose}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              ease: "easeOut",
              duration: 0.15,
              delay: 0,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              ease: "easeIn",
              duration: 0.15,
              delay: 0,
            },
          }}
        >
          {/* ---> Modal Close <---  */}
          <div className="top-lg right-lg fixed ">
            <div className="flex justify-end">
              <button onClick={onClose}>
                <CloseIcon classes={"text-white h-[1rem]"} />
              </button>
            </div>
          </div>
          {/* ---> Modal Container <---  */}
          <motion.div
            className="cursor-default"
            initial={{
              opacity: 0,
              scale: 0.95,
            }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: {
                ease: "easeOut",
                duration: 0.15,
                delay: 0.15,
              },
            }}
            exit={{
              opacity: 0,
              scale: 0.75,
              transition: {
                ease: "easeIn",
                duration: 0.15,
                delay: 0,
              },
            }}
          >
            {/* ---> Modal Content <---  */}
            <div
              className="relative overflow-y-none h-screen w-full p-xl select-none"
              role={"button"}
              onClick={() => onClose()}
            >
              {children}
            </div>
          </motion.div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
