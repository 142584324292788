import React from "react";
import { navigate } from "gatsby";
import { ArrowRegIcon } from "../../images/icons";
export const BackBtn = ({ classes }) => {
  return (
    <button
      className={`flex flex-row items-center ${classes}`}
      onClick={() => navigate(-1)}
    >
      <ArrowRegIcon classes={`h-[1rem] mr-md -rotate-180 mr-sm`} />
      <div className="">Back</div>
    </button>
  );
};
