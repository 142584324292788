// This is the template for a single sign
import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/shared/layout";
import { Seo } from "../components/shared/seo";
import Modal from "../components/shared/modal";
import { LinkType, OverlayImage } from "../components/shared/links";
import { WorkNavbar } from "../components/works/workNavbar";
import { BackBtn } from "../components/shared/backBtn";
import { Carousel } from "../components/shared/carousel";
import { PlayIcon, ArrowUpSelect } from "../images/icons";
import {
  responsiveIconsBasic,
  responsiveTextBasic,
} from "../components/shared/styles";
// ------------> WORK (single) page <------------ //
const WorkPageTemplate = (props) => {
  // --->  Data <--- //
  const work = props.data.strapiWork;
  const {
    title,
    artist,
    images,
    year,
    mediums,
    collection,
    workText,
    edition,
    videoLink,
    // featured,
    mediumDetails,
  } = work;
  //  - Next Button - //
  // const nextWork = props.pageContext.nextWorkSlug;
  //  - Is Series - //
  const isSeries = Object.keys(images[0])[0] === "item";
  // - Testing possible solutios for series carousel
  const [selectedItem, setSelectedItem] = React.useState(null);
  // ---> Modal Setup <--- //
  const [showModal, setShowModal] = React.useState(false);
  const handleOnClose = () => setShowModal(false);

  // ---- Series Item selected?
  const sereisItemSelected = (itemId, selectedItem) => {
    return itemId !== selectedItem && selectedItem != null
      ? "hidden"
      : itemId === selectedItem || (selectedItem === null && "block ");
  };

  React.useEffect(() => {}, [selectedItem]);
  return (
    <Layout location={props.location}>
      {/* --------- WORK NAVBAR ---------  */}
      {/* - is series? -  */}
      <WorkNavbar
        isSeries={isSeries}
        artist={artist}
        title={title}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />

      {/* --------- SERIES? --------- */}

      {isSeries ? (
        images.map((series, index) => {
          // --- Convert Item list to Array
          var serieses = Object.keys(series).map((key) => {
            return series[key];
          });
          return (
            /* --- Series Items */
            <div
              id={"series-container"}
              className={` ${
                selectedItem === null
                  ? "flex justify-center justify-self-center"
                  : ""
              }`}
              key={index}
            >
              {/* // Empty 1/2 */}
              <div
                className={`basis-1/4 xs:basis-0 ${
                  selectedItem != null ? "hidden" : "block"
                }`}
              />
              <div
                className={`md:basis-1/2 xs:basis-full ${
                  selectedItem != null
                    ? ""
                    : "w-[50vw] xs:w-[100vw] md:w-[50vw]"
                }`}
              >
                <div
                  className={`${
                    selectedItem != null
                      ? "grid-cols-1"
                      : "grid-cols-2 xs:grid-cols-2 md:grid-cols-2"
                  } grid  xs:gap-sm xl:gap-xl`}
                >
                  {serieses.map((items, index) => {
                    // --- Items
                    return items.map((item, index) => {
                      const seriesHerolImage = getImage(
                        item.image[0].imageFile.localFile.childImageSharp
                      );
                      return (
                        <div
                          id={"carousele"}
                          className={` 
                        ${
                          selectedItem === null ? " flex justify-center " : ""
                        }  
                        ${sereisItemSelected(item.id, selectedItem)}`}
                          key={index}
                        >
                          {/* IF SERIES ITEMS - Images */}
                          <button
                            onClick={() => setSelectedItem(item.id)}
                            className={` ${
                              selectedItem != null ? "hidden" : "relative"
                            }`}
                          >
                            <div className={" text-center select-none"}>
                              <GatsbyImage
                                image={seriesHerolImage}
                                alt={""}
                                className="xs:max-w-[300px] xs:h-[150px] sm:max-w-[400px] sm:h-[180px] md:max-w-[500px] md:h-[150px]  lg:max-w-[500px] lg:h-[150px]   xl:max-w-[500px] xl:h-[250px] "
                                objectFit={"cover"}
                                objectPosition={"center center"}
                              />
                              <OverlayImage title={item} />
                            </div>
                          </button>
                          {/* --- Logic for if pressed show Images */}
                          {item.id === selectedItem ? (
                            <div>
                              <Carousel
                                dataImages={item.image}
                                height={"h-[33vh] md:h-[33vh] lg:h-[50vh]"}
                                sereis={setSelectedItem}
                              />
                            </div>
                          ) : null}
                        </div>
                      );
                    });
                  })}
                </div>
                {/* SELECT SERIES ARROW  */}
                <div
                  className={`${
                    selectedItem != null
                      ? "hidden"
                      : "basis-full flex justify-center items-center opacity-50 mt-16"
                  }`}
                >
                  <ArrowUpSelect classes={`${responsiveIconsBasic}  mr-5`} />
                  <div className={`${responsiveTextBasic}`}>Select</div>
                </div>
              </div>
              {/* Empty end */}
              <div
                className={`basis-1/4 xs:basis-0 ${
                  selectedItem != null ? "hidden" : "block"
                }`}
              />
            </div>
          );
        })
      ) : (
        /* --------- NO SERIES? --------- */
        <Carousel
          dataImages={images[0].image}
          height={"h-[33vh] md:h-[33vh] lg:h-[50vh]"}
          classes={""}
        />
      )}

      {/* -------- Work Details -------- */}
      <div className="grid grid-cols-4 mt-xxl gap-x-xl gap-y-xl">
        <div className="xs:hidden md:col-span-1 md:block"></div>
        {/* - Title - */}
        <div className="xs:col-span-4 md:col-span-3 xs:text-md lg:text-lg capitalize ">
          {title && title}
        </div>

        <div className="xs:col-span-4 md:col-span-1 xs:order-2 md:order-1">
          {/* - Year - */}
          <div className="text-base">Year:</div>
          <div className="mb-sm">{year && year}</div>
          {edition ? (
            <div className="mb-sm">
              <div className="text-base">Edition:</div>
              <div>{edition}</div>
            </div>
          ) : null}

          <div className="text-base mb-xs">Mediums:</div>
          {/* - Medium - */}
          <div className="flex mb-[0.75rem]">
            {mediums
              ? mediums.map((medium, index) => {
                  return (
                    <LinkType
                      slug={"medium"}
                      type={medium.mediumType}
                      key={index}
                      isMedium={true}
                      classes={""}
                    />
                  );
                })
              : null}
          </div>

          <div className="text-sm  mb-md">{mediumDetails}</div>

          {/* - Video Link/s - */}

          {videoLink &&
            videoLink.map((video, index) => {
              return (
                <div key={index} className={"mb-sm"}>
                  {/* Video Link MODAL  */}
                  <button
                    className=" minline-flex justify-center text-base font-medium hover:text-gray-500 focus:outline-none focus:ring-0  focus:ring-transparent"
                    onClick={() => setShowModal(!showModal)}
                  >
                    <div className="flex justify-center items-center mb-sm">
                      <PlayIcon classes={"h-[0.75rem] mr-2"} />
                      <div>Link to video</div>
                    </div>
                  </button>
                  <Modal
                    modalData={{ link: video.link, work: title }}
                    visible={showModal}
                    onClose={handleOnClose}
                  />
                  {/* Duration */}
                  <div className="text-base">Duration:</div>
                  <div className="text-sm">{video.duration}</div>
                </div>
              );
            })}
          {/* - Collection */}
          <div className="text-base mb-xs">Collection:</div>
          <div className="flex mb-[0.75rem]">
            {collection ? (
              <div className="text-sm  mb-md">{collection.collectionName}</div>
            ) : null}
          </div>
        </div>

        <div className="xs:col-span-4 md:col-span-3 xs:order-1 md:order-2">
          {/* - Text - */}
          <div
            className="text-box"
            dangerouslySetInnerHTML={{
              __html: workText.data.workText && workText.data.workText,
            }}
          />
        </div>
      </div>

      {/* ---> BACK  BTN <--- */}
      <BackBtn classes={"my-xl"} />
    </Layout>
  );
};

export const query = graphql`
  query ($title: String) {
    site {
      siteMetadata {
        title
      }
    }
    strapiWork(title: { eq: $title }) {
      title
      featured
      mediumDetails
      mediums {
        mediumType
      }
      collection {
        collectionName
      }
      year
      workText {
        data {
          workText
        }
      }
      edition
      videoLink {
        duration
        link
      }

      images {
        ... on STRAPI__COMPONENT_IMAGES_SERIES {
          item {
            image {
              imageFile {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: NO_CHANGE
                      width: 1200
                      #height: 100
                      quality: 100
                      #layout: FULL_WIDTH
                      #transformOptions: { fit: COVER }
                      layout: CONSTRAINED
                    )
                  }
                }
              }
              imageCaption
            }
            itemTitle
            id
          }
        }
        ... on STRAPI__COMPONENT_IMAGES_IMAGES {
          image {
            imageCaption
            imageFile {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: NO_CHANGE
                    width: 1200
                    #height: 100
                    quality: 100
                    #layout: FULL_WIDTH
                    #transformOptions: { fit: COVER }
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
      artist {
        slug
        firstName
        lastName
      }
    }
  }
`;

export default WorkPageTemplate;

// *---> SEO
export const Head = () => (
  <Seo
    title={"Artists"}
    description={"IlCollection Artists"}
    keywords={"IlCollection Artists, Art, Israeli Art Collection"}
  />
);
