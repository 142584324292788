import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  CarruselArrowRight,
  ItemsIcon,
  ArrowRegIcon,
} from "../../images/icons";
import { LinkIcon } from "./links";
import ModalZoom from "./modalZoom";
import { responsiveIconsBasic } from "./styles";

// CAROUSEL COMPONENT
// Enter Height in VH
export const Carousel = ({ dataImages, height, sereis, classes, modal }) => {
  const [[page, direction], setPage] = useState([0, 0]);

  const imageIndex = wrap(0, dataImages.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };
  const theImages = getImage(
    dataImages[imageIndex].imageFile.localFile.childImageSharp.gatsbyImageData
  );
  // Caption data
  const imageCaption = dataImages[imageIndex].imageCaption;
  const imageCaptionItalic = /;(.*?);/g;
  const imageCaptionTitle = imageCaption.replace(
    imageCaptionItalic,
    `<span class="italic">$&</span>`
  );
  const imageCaptionCommas = imageCaptionTitle.replace(/;/g, ",");

  // ---> Modal Setup <--- //
  const [showModal, setShowModal] = React.useState(false);
  const handleOnClose = () => setShowModal(false);

  return (
    <div className={classes}>
      <div className={` relative overflow-x-hidden select-none`}>
        <AnimatePresence initial={false} custom={direction} mode={"popLayout"}>
          <motion.div
            className="w-full h-full"
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },

              opacity: { duration: 0.2 },
            }}
          >
            {!modal ? (
              <button
                className=" w-full h-full group"
                onClick={() => setShowModal(!showModal)}
              >
                <GatsbyImage
                  image={theImages}
                  alt={""}
                  className={`w-full ${height}`}
                  objectFit={"contain"}
                  objectPosition={"center"}
                />
              </button>
            ) : (
              <GatsbyImage
                image={theImages}
                alt={""}
                className={`w-full ${height}`}
                objectFit={"contain"}
                objectPosition={"center"}
              />
            )}
          </motion.div>
        </AnimatePresence>
        {!modal ? (
          <ModalZoom
            // modalData={{ link: video.link, work: title }}
            visible={showModal}
            onClose={handleOnClose}
          >
            <GatsbyImage
              image={theImages}
              alt={""}
              className={`w-full ${"h-full"} `}
              objectFit={"contain"}
              objectPosition={"center center"}
            />
          </ModalZoom>
        ) : null}
        {/* Arrows  */}
        {dataImages.length > 1 ? (
          <div>
            <div
              className={`absolute top-0 left-0 h-full ${
                modal && modal ? "blackGraidentLeft" : "whiteGraidentLeft"
              }`}
            >
              <LinkIcon
                classes={"flex justify-center items-center h-full"}
                onClick={() => paginate(1)}
              >
                <CarruselArrowRight
                  classes={`xs:w-[3rem] md:w-[4rem] h-auto scale-[-1] p-xs `}
                  color={modal && modal ? "#FFF" : "#000"}
                />
              </LinkIcon>
            </div>

            <div
              className={`absolute top-0 right-0 h-full ${
                modal && modal ? "blackGraidentRight" : "whiteGraidentRight"
              }`}
            >
              <LinkIcon
                classes={"flex justify-center items-center h-full "}
                onClick={() => paginate(-1)}
              >
                <CarruselArrowRight
                  classes={"xs:w-[3rem] md:w-[4rem] h-auto p-xs"}
                  color={modal && modal ? "#FFF" : "#000"}
                />
              </LinkIcon>
            </div>
          </div>
        ) : null}
        {/* Arrows End  */}
      </div>
      <div className={`mt-5 flex items-center ${!sereis ? "" : ""}`}>
        {sereis ? (
          <button onClick={() => sereis(null)} className={"basis-1/4"}>
            <LinkIcon classes={""}>
              <div className="flex justify-start items-center ">
                <ArrowRegIcon
                  classes={`mr-md -rotate-180 ${responsiveIconsBasic}`}
                />
                <ItemsIcon classes={` ${responsiveIconsBasic}`} />
              </div>
            </LinkIcon>
          </button>
        ) : null}
        <div
          className={`flex justify-center ${
            sereis ? "basis-1/2" : "basis-full"
          }`}
        >
          {/* <div className="text-sm">{dataImages[imageIndex].imageCaption}</div> */}
          <div
            className="text-sm"
            dangerouslySetInnerHTML={{ __html: imageCaptionCommas }}
          ></div>
        </div>
        <div className=""></div>
      </div>
    </div>
  );
};
// FRAMER MOTION VARIANT
const variants = {
  enter: (direction) => {
    return {
      opacity: 0,
      x: direction > 0 ? 1000 : -1000,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};
